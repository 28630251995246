import React from "react";
import { useParams } from "react-router-dom";
import Details from "../../components/Details/Details";
import { getAuth } from "firebase/auth";

const userHeaders = [
  "id",
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "ipAddress",
  "isAdmin",
  "isBO",
  "merchantID",
  "status",
  "createdAt",
  "updatedAt",
];
const merchantHeaders = [
  "id",
  "name",
  "address",
  "phoneNumber",
  "email",
  "website",
  "bio",
  "logoURL",
  "BO_UserID",
  "status",
  "createdAt",
  "updatedAt",
];

const DetailsPage = () => {
  const { userId, merchantId } = useParams<{
    userId: string;
    merchantId: string;
  }>();

  const auth = getAuth();
  const [data, setData] = React.useState<{ [key: string]: any }[]>([]);
  const [error, setError] = React.useState<string | null>(null);
  let headers: string[] = [];

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchData(user);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchData = async (user: any) => {
    if (!user) {
      console.error("No user signed in");
      setError("No user signed in");
      return;
    }

    console.log("User is signed in:", user);

    if (userId) {
      try {
        const token = await user.getIdToken();
        const response = await fetch(
          `https://admin.1-point.ca/api/getUser/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err instanceof Error ? err.message : String(err));
      }

      headers = userHeaders;
    } else if (merchantId) {
      headers = merchantHeaders;
    }
  };
  return <Details data={data} headers={headers} />;
};
export default DetailsPage;
