// Dashboard.tsx
import * as React from "react";
import Table from "../../components/Table/Table";
import { getAuth } from "firebase/auth";

// Sample static data for the tables
let users: { [key: string]: any }[] = [];

const merchants = [
  {
    id: "1",
    name: "Shawerma Plus - Waterloo",
    address: "160 University Ave W, Waterloo, ON N2L 3E9",
    phoneNumber: "(519) 208-5552",
    email: "info@shawermaplus.com",
    website: "https://shawermaplus.com/",
    bio: "Shawarma Plus is hands down the go-to spot for the tastiest shawarmas in the Greater Toronto Area. Packed with genuine Syrian flavors.",
    logoURL: "https://merchant1.com/logo.png",
    BO_UserID: "5MfGahWyS6MphLOz2vVo0VO1GBQ2",
    status: "Active",
    createdAt: "2024-09-15T23:12:25.000Z",
    updatedAt: "2024-11-05T17:03:17.000Z",
  },
  {
    id: "2",
    name: "Merchant 2",
    address: "456 Elm St",
    phoneNumber: "555-5678",
    email: "merchant2@example.com",
    website: "https://merchant2.com",
    bio: "This is a sample bio for Merchant 2.",
    logoURL: "https://merchant2.com/logo.png",
    BO_UserID: "9UiKGt3yS6MphLOz2vVo0VO1GBQ2",
    status: "Inactive",
    createdAt: "2024-10-01T00:00:00.000Z",
    updatedAt: "2024-10-01T00:00:00.000Z",
  },
];

const userHeaders = [
  "id",
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "ipAddress",
  "isAdmin",
  "isBO",
  "merchantID",
  "status",
  "createdAt",
  "updatedAt",
];
const merchantHeaders = [
  "id",
  "name",
  "address",
  "phoneNumber",
  "email",
  "website",
  "bio",
  "logoURL",
  "BO_UserID",
  "status",
  "createdAt",
  "updatedAt",
];

const Dashboard = () => {
  const auth = getAuth();
  const [data, setData] = React.useState<{ [key: string]: any }[]>([]);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchData(user);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchData = async (user: any) => {
    if (!user) {
      console.error("No user signed in");
      setError("No user signed in");
      return;
    }

    console.log("User is signed in:", user);

    try {
      const token = await user.getIdToken();
      const response = await fetch("https://admin.1-point.ca/api/getUsers", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err instanceof Error ? err.message : String(err));
    }
  };
  return (
    <div>
      <div>
        <Table
          entityType="user"
          title="User Table"
          headers={userHeaders}
          data={data}
        />
      </div>
      <div>
        <Table
          entityType="merchant"
          title="Merchant Table"
          headers={merchantHeaders}
          data={merchants}
        />
      </div>
    </div>
  );
};

export default Dashboard;
