import React from "react";
import { useParams, useNavigate } from "react-router-dom";

// Define props for the Details component
interface DetailsProps {
  data: Array<{ [key: string]: any }>;
  headers: string[];
}

const Details: React.FC<DetailsProps> = ({ data, headers }) => {
  const { userId, merchantId } = useParams<{
    userId: string;
    merchantId: string;
  }>();
  const navigate = useNavigate();

  // Find the item with the matching userId or merchantId
  const item = data.find(
    (item) => item.id === userId || item.id === merchantId
  );

  if (!item) {
    return <div>Item not found</div>;
  }

  const handleSave = () => {
    alert(`Details for ID ${item.id} saved (this is a demo, no data saved)`);
    navigate("/dashboard");
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Edit Details</h2>
      <form style={styles.form}>
        {headers.map((header) => (
          <label key={header} style={styles.label}>
            {header}:
            {header === "isBO" ? (
              <select
                defaultValue={item[header] ? "true" : "false"}
                style={styles.select}
              >
                <option value="true">true</option>
                <option value="false">false</option>
              </select>
            ) : (
              <input
                type={header === "email" ? "email" : "text"}
                defaultValue={item[header]}
                readOnly={
                  header === "id" ||
                  header === "ip" ||
                  header === "BO_UserID" ||
                  header === "createdAt" ||
                  header === "updatedAt"
                }
                style={styles.input}
              />
            )}
          </label>
        ))}
        <button type="button" onClick={handleSave} style={styles.button}>
          Save
        </button>
      </form>
    </div>
  );
};

// Style objects for dark theme
const styles = {
  container: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    padding: "20px",
    margin: "20px",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  },
  heading: {
    color: "#FFFFFF",
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  label: {
    marginBottom: "15px",
    color: "#CCCCCC",
    display: "flex",
    flexDirection: "column" as "column",
  },
  input: {
    padding: "10px",
    margin: "5px 0",
    backgroundColor: "#1E1E1E",
    color: "#FFFFFF",
    border: "1px solid #333333",
    borderRadius: "4px",
  },
  select: {
    padding: "10px",
    margin: "5px 0",
    backgroundColor: "#1E1E1E",
    color: "#FFFFFF",
    border: "1px solid #333333",
    borderRadius: "4px",
  },
  button: {
    padding: "10px",
    backgroundColor: "#0A2744",
    color: "#FFFFFF",
    border: "none",
    cursor: "pointer",
    marginTop: "20px",
    borderRadius: "4px",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#007BFF",
  },
};

export default Details;
